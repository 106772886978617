<template>
  <div>
    <!-- <page-header :tabsItems="tabsItems" :title="title"></page-header> -->
    <v-container fluid v-if="!loaded">
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="mb-3 py-2">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-card>
          <v-card outlined tile class="py-5" v-for="i in 2" :key="i">
            <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-else>
      <v-row justify="center" class="mb-10">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="mb-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  총 {{ linkList.length }} 개
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn class="d-none d-md-flex" color="light-green" tile dark @click="addItem"><v-icon>mdi-plus</v-icon> 신규등록</v-btn>
                <v-btn class="d-flex d-md-none" color="light-green" tile dark small @click="addItem"><v-icon>mdi-plus</v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <div v-if="linkList.length > 0">
            <v-card outlined tile>
              <draggable v-model="linkList" @change="changeItem">
                <template v-for="item in linkList">
                  <link-item
                    :item="item"
                    :key="item.order"
                    @editItem="editItem"
                    @delItem="delItem"
                    @goLink="goLink"
                  ></link-item>
                </template>
              </draggable>
            </v-card>
          </div>
          <v-card outlined tile class="text-center" v-else>
            <v-sheet class="py-5 text-h6">즐겨찾기가 없습니다</v-sheet>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-bottom-sheet
      v-model="sheet"
      persistent
      inset
    >
      <v-sheet
        class="text-center"
        height="240"
      >
        <v-form ref="form" lazy-validation>
          <v-card flat>
            <v-card flat height="30">
            </v-card>
            <v-list-item>
              <v-text-field
                v-model="newLink.title"
                outlined
                label="제목을 입력하세요"
                autocomplete="Off"
                dense
                :rules="[rule.required]"
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-text-field
                v-model="newLink.linkUrl"
                outlined
                label="즐겨찾기 주소(http, https)를 입력하세요"
                autocomplete="Off"
                dense
                :rules="[rule.required, rule.webUrl]"
              ></v-text-field>
            </v-list-item>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                class="mb-5"
                text
                large
                @click="sheet = !sheet"
              >취소</v-btn>
              <v-btn
                class="mb-5 mr-2"
                tile outlined
                color="primary"
                large
                @click="saveItem"
              >저장</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
// import PageHeader from '../PageHeader'
import LinkItem from '@/components/widgets/LinkItem'
import draggable from 'vuedraggable'

export default {
  components: { LinkItem, draggable },
  data () {
    return {
      title: '학원관리',
      tabsItems: [
        { id: 1, title: '학원정보', link: '/manage/academy' },
        { id: 2, title: '학생관리', link: '/manage/students' },
        { id: 3, title: '포인트', link: '/manage/point' },
        { id: 4, title: '즐겨찾기', link: '/manage/links' }
      ],
      links: [],
      loaded: false,
      linkList: [],
      sheet: false,
      newLink: {
        title: '',
        linkUrl: ''
      },
      rule: {
        required: v => !!v || '필수 항목입니다.',
        webUrl: v => {
          const pattern = /^(http|https):\/\/(.*)$/
          return pattern.test(v) || 'http, https, file 주소가 아닙니다.'
        }
      }
    }
  },
  created () {
    this.read()
  },
  methods: {
    async read () {
      let academyid = ''
      this.loaded = false
      try {
        academyid = this.$store.state.user.uid
      } catch (e) {
        academyid = localStorage.acid
      }

      const r = await this.$firebase.firestore()
        .collection('academies').doc(academyid)
        .collection('infos').doc('links').get()

      if (r.exists) {
        this.linkList = r.data().links
      } else {
        this.linkList = []
      }

      this.loaded = true
    },
    addItem () {
      this.sheet = !this.sheet
      this.newLink.title = ''
      this.newLink.linkUrl = ''
      try {
        this.$refs.form.reset()
      } catch (e) {
        //
      }
    },
    async saveItem () {
      // {
      //   title: 'BBB2',
      //   linkUrl: 'http://localhost:8080/manage/BBB2',
      //   order: 4,
      //   type: 'WEB',
      //   createAt: new Date()
      // }
      if (!this.$refs.form.validate()) return

      const item =
        {
          title: this.newLink.title,
          linkUrl: this.newLink.linkUrl,
          order: 0,
          createAt: new Date()
        }

      this.linkList.unshift(item)

      await this.setDB()

      this.sheet = !this.sheet
    },
    changeItem () {
      this.setDB()
    },
    async setDB () {
      let cnt = 0
      this.linkList.forEach(doc => {
        doc.order = cnt
        cnt++
      })

      await this.$firebase.firestore()
        .collection('academies').doc(this.$store.state.user.uid)
        .collection('infos').doc('links').set({ links: this.linkList })
    },
    editItem (item) {
      this.setDB()
    },
    delItem (item) {
      this.linkList = this.linkList.filter(function (n) {
        return n.order !== item.order
      })

      this.setDB()
    },
    goLink (item) {
      if (item.linkUrl) window.open(item.linkUrl, 'PopupLink')
    }
  }
}
</script>
