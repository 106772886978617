<template>
  <div>
    <v-list-item two-line>
      <v-list-item-icon style="cursor:move">
        <v-icon large class="mt-2">mdi-drag</v-icon>
      </v-list-item-icon>
      <v-list-item-content @click="goLink" style="cursor:pointer">
        <div v-if="!edit">
          <v-list-item-title>{{item.title}}</v-list-item-title>
          <v-list-item-subtitle>{{item.linkUrl}}</v-list-item-subtitle>
        </div>
        <div v-else>
          <v-text-field
            dense
            v-model="item.title"
          ></v-text-field>
          <v-text-field
            dense
            v-model="item.linkUrl"
          ></v-text-field>
        </div>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn text @click="editSave">
          <v-icon>{{!edit ? 'mdi-pencil' : 'mdi-content-save'}}</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn text @click="delItem"><v-icon>mdi-close</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
  </div>
</template>
<script>
export default {
  props: ['item'],
  data () {
    return {
      edit: false
    }
  },
  methods: {
    editSave () {
      this.edit = !this.edit
      if (!this.edit) {
        this.$emit('editItem', this.item)
      }
    },
    goLink () {
      if (this.edit) return
      this.$emit('goLink', this.item)
    },
    delItem () {
      this.$emit('delItem', this.item)
    }
  }
}
</script>
